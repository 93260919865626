import Cookies from "universal-cookie/es6";
import { Routes } from "../routes";

const cookies = new Cookies();

export const getAuthorizationHeader = () => {
  return {
    Authorization: `JWT ${cookies.get("jwttoken")}`,
  };
};

export const setAuthorizationHeader = (response, maxAge = undefined) => {
  cookies.set("jwttoken", response.headers.get("jwttoken"), {
    path: Routes.mainPage.base,
    maxAge: maxAge
  });
};

export const preparePostFormBody = (data) => {
  const formData = new FormData();

  Object
    .entries(data)
    .filter(([, value]) => value !== undefined)
    .forEach(([key, value]) => {
      formData.append(key, value);
    });

  return formData;
};
