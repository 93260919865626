import { useContext, useEffect, useState } from "react";
import styles from "./NavBar.module.scss";
import logo from "../../../assets/aidify-logo.svg";
import NavbarInput from "../../atoms/NavbarInput/NavbarInput";
import ContextSelect from "../../molecules/ContextSelect/ContextSelect";
import DropdownNav from "../../molecules/DropdownNav/DropdownNav";
import { languages } from "../../../dummyData/dummyData";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";
import useFetch from "../../../hooks/useFetch";
import { notificationsRepository } from "../../../repository/notifications.repository";
import { LoaderOnly } from "../../atoms/Loader/Loader";
import { UserContext } from "../../../contexts/UserContext";
import { PostContext } from "../../../contexts/PostContext";

const NavBar = ({ transparent = false, simplified = false, refreshContextOnLogoClick = true }) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const { userData, userContextLevel, handleSwitchHomepage, getUserContext } = useContext(UserContext);
  const { isResponseOk, lastUsedPromise } = useContext(PostContext);
  const { data, isReady, fetchData } = useFetch();
  const [linksForProfile, setLinksForProfile] = useState([[], []]);
  const [inputIsActive, setInputIsActive] = useState(false);

  useEffect(() => {
    if ((isResponseOk && lastUsedPromise === 'markReadNotification') || !data) {
      fetchData(notificationsRepository.getUserNotifications(0, 6));
    }
  }, [isResponseOk]);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setIsScrollingDown(scrollY > lastScrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isScrollingDown]);

  useEffect(() => {
    setLinksForProfile(languages.EN.navBar.userLinks(userContextLevel));
  }, [userContextLevel]);

  return (
    <header className={styles.nav} data-transparent={transparent} data-is-scrolling-down={isScrollingDown}>
      {inputIsActive && <div className={styles.shadow}></div>}
      <div className={styles.layout}>
        <div className={styles.navLeftSide}>
          <img onClick={() => refreshContextOnLogoClick ? getUserContext((data) => handleSwitchHomepage(data.current.context_type, data.current.context_id)) : handleSwitchHomepage()} src={logo} alt="Aidify Logo" />
          {!simplified && (
            <div className={styles.navBarContainer}>
              <NavbarInput
                isScrollingDown={isScrollingDown}
                setInputIsActive={setInputIsActive}
              />
              {userData?.contexts.length > 1 && <ContextSelect />}
            </div>
          )}
        </div>

        <div className={styles.navRightSide}>
          <>
            {!simplified && <DropdownNav items={languages.EN.navBar.menuLinks} />}
            {userData && <DropdownNav items={simplified ? [[], []] : linksForProfile} profile />}
            {!simplified && (isReady ? (
              <NotificationDropdown counter={data.unread_count} data={data.notifications} />
            ) : (
              <LoaderOnly />
            ))}
          </>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
